<template>
  <div>
    <!--begin::Content header-->
    <div
      class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
    ></div>
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mt-10 mt-lg-20">
        <h3 class="font-size-h1" style="color: #e33354">
          {{ $t("AUTH.LOGIN.SIGN_IN") }}
        </h3>
        <p
          v-if="!isrequire2f"
          class="font-weight-semi-bold"
          style="color: #e33354"
        >
          {{ $t("AUTH.LOGIN.TEXT") }}!
        </p>
        <p v-else class="font-weight-semi-bold" style="color: #e33354">
          {{ $t("AUTH.LOGIN.TEXT2F") }}
        </p>
      </div>
      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <!-- <div role="alert" class="alert alert-info">
          <div class="alert-text">
            Use account <strong>admin@demo.com</strong> and password
            <strong>demo</strong> to continue.
          </div>
        </div> -->
        <!-- <pre>{{ errors }}</pre> -->
        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <b-form-group
          v-show="!isrequire2f"
          id="example-input-group-1"
          label=""
          label-for="example-input-1"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            :placeholder="$t('AUTH.INPUT.EMAIL')"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            {{ $t("ERRORS.email_is_valid") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          v-show="!isrequire2f"
          id="example-input-group-2"
          label=""
          label-for="example-input-2"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            :placeholder="$t('AUTH.INPUT.PASSWORD')"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            {{ $t("ERRORS.password_is_required") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          v-if="isrequire2f"
          id="example-input-group-3"
          label=""
          label-for="example-input-3"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-3"
            name="example-input-3"
            :placeholder="$t('AUTH.INPUT.one_time_password')"
            v-model="$v.form.one_time_password.$model"
            :state="validateState('one_time_password')"
            aria-describedby="input-3-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-3-live-feedback">
            {{ $t("ERRORS.one_time_password_is_valid") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-center align-items-center"
        >
          <button
            ref="kt_login_signin_submit"
            class="btn btn-secondary font-weight-bold px-9 py-4 my-3 font-size-3"
            :style="buttonStyle"
          >
            {{ $t("AUTH.LOGIN.BUTTON") }}
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        // email: "superadmin@neckermann.hu",
        // password: "123456Ab",
        email: "",
        password: "",
        one_time_password: "",
      },
      buttonStyle: {
        backgroundColor: "#e33354",
        color: "#fff",
        borderColor: "transparent",
        margin: "0 auto",
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
      one_time_password: {
        // required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
        one_time_password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;
      const one_time_password = this.$v.form.one_time_password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { email, password, one_time_password })
          // go to which page after successfully login
          .then((data) => {
            if (data.success) {
              // console.log("onSubmit.data ", data);
              localStorage.removeItem("selectedLanguage");

              const TO_PATH = window.localStorage.getItem("TO_PATH");
              if (TO_PATH && TO_PATH != "/") {
                sessionStorage.removeItem("actual_directory");
                window.localStorage.setItem("active_site_id", 0);

                window.localStorage.removeItem("TO_PATH");
                this.$router.push({ path: TO_PATH });
              } else {
                this.$router.push({ name: "dashboard" });
              }
            }
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 10);
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
      isrequire2f: (state) => state.auth.require2f,
    }),
  },

  mounted() {
    // let routeName = this.$route.name;
    // alert(routeName);
    // localhost:8080/#/cms/internal_documents?directory=internal_documents%2Fteszt1111&filePath=internal_documents%2Fteszt1111%2Fworddoc1.doc
  },
};
</script>
